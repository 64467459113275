<template>
  <div v-if="hasAbilities()" class="p-grid p-fluid dashboard">

    <div class="p-col-12 p-lg-3">
      <div class="card summary">
        <span class="title">Empleados</span>
        <span class="detail">Cantidad de Empleados</span>
        <span class="count visitors">{{counters.employees}}</span>
      </div>
    </div>
    <div class="p-col-12 p-lg-3">
      <div class="card summary">
        <span class="title">Planes</span>
        <span class="detail">Tipos de Planes</span>
        <span class="count purchases">{{counters.plans}}</span>
      </div>
    </div>
    <div class="p-col-12 p-lg-3">
      <div class="card summary">
        <span class="title">Organizaciones</span>
        <span class="detail">Cantidad de Organizaciones</span>
        <span class="count revenue">{{counters.organizations}}</span>
      </div>
    </div>
    <div class="p-col-12 p-lg-3">
      <div class="card summary">
        <span class="title">Sin NBS3</span>
        <span class="detail">Matrículas por migrar</span>
        <span class="count revenue">{{counters.enrollments_pm}}</span>
      </div>
    </div>



    <div class="p-col-12 p-lg-6">
      <div class="card">
        <div id="chart" style="width: 100%;height: 360px"  />
      </div>
    </div>
    <div class="p-col-12 p-lg-6">
      <div class="card">
        <div id="chart2" style="width: 100%;height: 360px"  />
      </div>
    </div>

  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">No tienes opciones asignadas a tu cargo</p>
      <Button label="Salir" class="p-button-danger p-mr-2"
              @click="logout()"/>
    </div>
  </div>
</template>

<script>
import bouncer from "../../helpers/bouncer";
import counterService from "../layout/service/counter.service"
import Apexcharts from "apexcharts";


export default {
  data() {
    return {
      counters: {
        'employees': 0,
        'plans': 0,
        'organizations': 0,
        'enrollments_pm': 0,
        'liquidations_n': 0,
        'liquidations_a': 0,
        'liquidations_v': 0,
        'liquidations_d': 0,

        'process_mat_n': 0,
        'process_mat_g': 0,
        'process_mat_a': 0,
        'process_mat_d': 0,
        'process_mat_p': 0,
        'process_mat_ps': 0
      }
    }
  },
  mounted() {
    if(this.hasAbilities()){
      counterService.counter().then(x => {
        this.counters=x.data
        this.chart1()
        this.chart2()
      });
    }


  },
  mixins: [bouncer],
  methods: {
    logout() {
      localStorage.clear();
      this.$router.push({name: 'login'})
    },
    chart1(){
      var options = {

        title: {
          text: 'Estado global actual de procesos de liquidación',
          align: 'left'
        },

        series: [{
          name: 'Estados de Liquidaciones',
          data: [parseInt(this.counters.liquidations_n),parseInt(this.counters.liquidations_v),
            parseInt(this.counters.liquidations_a),parseInt(this.counters.liquidations_d)]
        }],
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
          }
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: ['Nuevas','Validadas','Aprobadas','Desaprobadas'
          ],
        }
      };

      var chart = new Apexcharts(document.querySelector("#chart"), options);
      chart.render();


    },
    chart2(){
      var options = {

        title: {
          text: 'Estado Global de Matrículas',
          align: 'left'
        },
        series: [{
          name: 'Estados Global de Matrículas',
          data: [parseInt(this.counters.process_mat_n)
            ,parseInt(this.counters.process_mat_g)
            ,parseInt(this.counters.process_mat_ps)
            ,parseInt(this.counters.process_mat_a)
            ,parseInt(this.counters.process_mat_d)
            ,parseInt(this.counters.process_mat_p)
          ]
        }],
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: ['Nuevas','Generadas','Postergadas','Aprobadas','Desaprobadas','Pagadas'
          ],
        }
      };

      var chart = new Apexcharts(document.querySelector("#chart2"), options);
      chart.render();


    },

  }
}
</script>

<style lang="scss" scoped>
</style>
